var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"labour-authentication"},[_c('div',{staticClass:"scroll-box"},[_c('van-form',{ref:"rcrz_form",staticClass:"rcrz-form",attrs:{"input-align":"right","error-message-align":"right","validate-trigger":"onSubmit","readonly":_vm.mode === 'view',"colon":true,"scroll-to-error":true,"show-error":false,"show-error-message":true,"submit-on-enter":false,"label-width":"6.4em"},on:{"submit":_vm.submitOnHandle}},[_c('div',{staticClass:"form-moudlar no-margin-top"},[_c('div',{staticClass:"moudlar-title"},[_c('div',{staticClass:"title-text"},[_vm._v("个人信息")])]),_c('van-field',{attrs:{"required":"","label":"姓名","placeholder":"请输入姓名","maxlength":10,"rules":[{ required: true, message: '姓名不能为空' }]},model:{value:(_vm.form.realName),callback:function ($$v) {_vm.$set(_vm.form, "realName", $$v)},expression:"form.realName"}}),_c('van-field',{attrs:{"required":"","label":"联系方式","placeholder":"请输入联系方式","maxlength":11,"rules":[
            { required: true, message: '联系方式不能为空' },
            {
              pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
              message: '手机号码不正确',
            },
          ]},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}}),_c('van-field',{attrs:{"required":"","label":"工作地点","placeholder":"请输入工作地点","rules":[{ required: true, message: '工作地点不能为空' }]},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}})],1)])],1),_c('div',{staticClass:"footer-btn"},[(![1, 0].includes(_vm.rzAuditStatus))?_c('div',{staticClass:"btn tj-btn",on:{"click":_vm.submitOnClick}},[_c('van-loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.submitLoading),expression:"submitLoading"}],staticStyle:{"margin-right":"10px"},attrs:{"type":"spinner","color":"#ffffff","size":"18"}}),_vm._v(" 提交 ")],1):_vm._e(),([1].includes(_vm.rzAuditStatus))?_c('div',{staticClass:"btn shtg-btn"},[_vm._v(" 审核通过 ")]):_vm._e(),([0].includes(_vm.rzAuditStatus))?_c('div',{staticClass:"btn dsh-btn",on:{"click":_vm.revoOnClick}},[_vm._v(" 待审核，点击撤回 ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }